import adviserDetails from './routes/adviser-details';
import policies, { RouteParams as PoliciesRouteParams } from './routes/policies';
import policyDetails from './routes/policy-details';
import groups, { RouteParams as GroupRouteParams } from './routes/groups';
import groupDetails from './routes/group-details';
import dashboard from './routes/dashboard';
import landing from './routes/landing';
import auth from './routes/auth.ts';
import migrateMe from './routes/migrate-me.ts';
import faq from './routes/faq.ts';
import legalAgreements from './routes/legal-agreements.ts';
import communications, { RouteParams as CommunicationsRouteParams } from './routes/communications';

export namespace RouteParams {
  export type Policies = PoliciesRouteParams;
  export type Groups = GroupRouteParams;
  export type Communications = CommunicationsRouteParams;
}

/*
  The reason this is manually constructed like this is so that the types are correctly defined.
  If you can come up with a way to dynamically generate these objects whilst maintining the type
  definitions then please update the code below.
*/

export const parsers = {
  adviserDetails: adviserDetails.parser,
  policies: policies.parser,
  policyDetails: policyDetails.parser,
  groups: groups.parser,
  groupDetails: groupDetails.parser,
  dashboard: dashboard.parser,
  landing: landing.parser,
  auth: auth.parser,
  migrateMe: migrateMe.parser,
  faq: faq.parser,
  legalAgreements: legalAgreements.parser,
  communications: communications.parser,
} as const;

export type Parsers = typeof parsers;

export const composers = {
  adviserDetails: adviserDetails.composer,
  policies: policies.composer,
  policyDetails: policyDetails.composer,
  groups: groups.composer,
  groupDetails: groupDetails.composer,
  dashboard: dashboard.composer,
  landing: landing.composer,
  auth: auth.composer,
  migrateMe: migrateMe.composer,
  faq: faq.composer,
  legalAgreements: legalAgreements.composer,
  communications: communications.composer,
} as const;

export type Composers = typeof composers;

const useUrlManager = () => {
  return {
    composers: {
      ...composers,
    },
  };
};

export default useUrlManager;
