/*
  This file defines the route definition for a given microfrontend. It is used as a means to centralise
  route definition, parsing, and composition.
*/

import { composeUrlWithParams, parseUrlParams } from '../utils.ts';

export type RouteParams = { salesCode: string; activeTab?: string };

/**
 * Regex use for matching the route.
 */
const routeRegex = /\/advisers\/([^\/]+)(|\/|\/.+)$/;

/**
 * A function which can be used to parse the route associated with this microfrontend.
 * The parser should return null, if the route does not match. An empty object if the route
 * matches but no parameters are required and an object containing the parameters if they are present.
 */
const parser = ({ pathname, href }: Pick<Location, 'pathname' | 'href'>): null | RouteParams => {
  const match = pathname.match(routeRegex);

  if (!match) {
    return null;
  }

  const searchParams = parseUrlParams(new URL(href).search);

  if (match[1]) {
    return {
      salesCode: match[1],
      ...(searchParams.activeTab ? { activeTab: searchParams.activeTab } : {}),
    };
  }

  return null;
};

/**
 * A function which can be used to compose the routes associated with this microfrontend.
 * This function should accept an parameters and return a composed route. If parameters are
 * optional, this function should accomodate that fact.
 */
function composer({ salesCode, activeTab }: RouteParams): string {
  return composeUrlWithParams(`/advisers/${salesCode}/`, activeTab ? { activeTab } : {});
}

export default {
  parser,
  composer,
};
